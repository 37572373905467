<template>
  <div>
    <!-- <button @click="payWithMonnify">Top Up</button> -->
    <button @click="openMonify">Top Up</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    amount: {
      type: [String, Number],
      default: 0,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    confirmTransaction: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState("session", ["keys"]),

    name() {
      return `${this.user?.name} ${this.user?.last_name}`;
    },
    email() {
      return this.user?.email;
    },
    phoneNo() {
      return this.user?.phone_no;
    },
    reference() {
      var string = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 32; i++) {
        string += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return string;
    },
  },
  methods: {
    payWithMonnify() {
      window.MonnifySDK.initialize({
        amount: this.amount,
        currency: "NGN",
        reference: this.reference,
        customerName: this.name,
        customerEmail: this.email,
        customerMobileNumber: this.phoneNo,
        apiKey: "MK_TEST_3RXJZFZ63Y",
        contractCode: "2845528941",
        paymentDescription: "Top up",
        isTestMode: true,
        metadata: {
          name: "Credpal",
        },
        onComplete: (response) => {
          //Implement what happens when transaction is completed.
          // console.log(response);
          this.$emit("confirmTransaction", response);
        },
        onClose: function() {
          //Implement what should happen when the modal is closed here
          // console.log(data);
        },
      });
    },
    openMonify() {
      this.$emit("openMonify");
      this.payWithMonnify();
    },
  },
};
</script>
